import Validator from 'validatorjs'
import lang from 'validatorjs/src/lang.js'
import CustomValidators from './CustomValidators'

import nb from 'validatorjs/src/lang/nb_NO'
const langs = { nb }

Object.keys(langs).forEach(key => {
  lang._set(key, langs[key])
})

function getPropByPath(data, path) {
  let keys = path.split('.')
  let current = keys.shift()
  let result = data[current]

  if (!keys.length) return result
  return getPropByPath(result, keys.join('.'))
}

Validator.useLang('nb')
Object.values(CustomValidators).forEach(func => func(Validator))

export default {
  methods: {
    isFieldValid(field) {
      return () => (this.validator.errors.get(field).length ? false : true)
    },
    getFieldError(field) {
      return this.validator.errors.first(field) || undefined
    },
  },
  created() {
    if (!this.validator) return console.log('No validator object defined. Skipping...')

    let placeholder = new Validator()

    let getRules = () => {
      if (typeof this.validator.rules === 'string') return getPropByPath(this, this.validator.rules)
      return this.validator.rules
    }

    let triggerListener = func => {
      if (!func) return
      return typeof func === 'string' ? this[func]() : func()
    }

    let getFields = () => {
      return Object.fromEntries(
        Object.keys(getRules() || {}).map(key => {
          return [
            key,
            {
              valid: this.validator.errors.get(key).length ? false : true,
              error: this.validator.errors.first(key) || undefined,
            },
          ]
        }),
      )
    }

    this.$set(this.validator, 'errors', placeholder.errors)
    this.$set(this.validator, 'passes', true)
    this.$set(this.validator, 'isFieldValid', this.isFieldValid.bind(this))
    this.$set(this.validator, 'getFieldError', this.getFieldError.bind(this))
    this.$set(this.validator, 'fields', getFields())

    let onChange = () => {
      let validation
      try {
        validation = new Validator(getPropByPath(this, this.validator.data), getRules())
      } catch (err) {
        console.log(err)
      }

      if (this.validator.labels) {
        validation.setAttributeNames(
          typeof this.validator.labels === 'string'
            ? getPropByPath(this, this.validator.labels)
            : this.validator.labels,
        )
      }

      const passes = () => {
        this.validator.errors = placeholder.errors
        this.validator.passes = true
        this.validator.fields = getFields()
        this.$emit('update:isValid', this.validator.passes)
        if (this.validator.listeners) {
          triggerListener(this.validator.listeners.passes)
        }
      }

      const fails = () => {
        this.validator.passes = false
        this.validator.errors = validation.errors
        this.validator.fields = getFields()
        this.$emit('update:isValid', false)
      }

      validation.checkAsync(passes, fails)
    }

    this.$watch(this.validator.data, onChange, { deep: true, immediate: true })
  },
}
